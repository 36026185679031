import React from 'react';
import classNames from 'classnames';

import Icon from 'common/Icon';
import Image from 'common/Image';
import Typography from 'common/Typography';

import { InfographicPropsType, TypographyMappedVariantType } from './model';

import './Infographic.scss';

const Infographic: React.FC<InfographicPropsType> = ({
  classname,
  variant = 'main',
  image,
  title,
  smallText,
  backgroundColor,
  icon,
}) => {
  const featureClassNames = classNames(
    'infographic',
    {
      [`infographic--${variant}`]: variant,
      [`background--${backgroundColor}`]: backgroundColor,
    },
    classname
  );

  const titleTypography: Partial<TypographyMappedVariantType> = {
    main: {
      size: { base: 60, xs: 109, md: 80, lg: 119, xxl: 174 },
      color: 'white',
      lineHeight: 'xsmall',
    },
    icon: {
      size: { base: 35, md: 44, xl: 65, xxl: 80 },
      color: 'deep-koamaru',
      align: 'center',
      weight: 'bold',
      lineHeight: 'xsmall',
    },
  };

  const descriptionTypography: Partial<TypographyMappedVariantType> = {
    main: {
      size: { base: 30, md: 32, lg: 55 },
      color: 'white',
      align: 'center',
      lineHeight: 'small',
    },
    icon: {
      size: { base: 18, lg: 21 },
      color: 'mine-shaft',
      align: 'center',
    },
  };

  const isMain = variant === 'main';

  return (
    <div className={featureClassNames} data-testid="feature-item">
      {isMain && image?.imageStructure ? (
        <Image
          className="infographic__image"
          {...image.imageStructure}
          objectFit={variant === 'main' ? 'cover' : 'contain'}
        />
      ) : null}
      {!isMain && icon ? <Icon className="infographic__image" {...icon} /> : null}
      {title ? (
        <Typography {...titleTypography[variant]} className="infographic__title">
          {title}
        </Typography>
      ) : null}
      {smallText ? (
        <Typography {...descriptionTypography[variant]} className="infographic__description">
          {smallText}
        </Typography>
      ) : null}
    </div>
  );
};

export default Infographic;
