import React, { FC } from 'react';

import Typography from 'common/Typography';

import { OurValuesSubtitlePropsType } from './models';

import './OurValuesSubtitle.scss';

const OurValuesSubtitle: FC<OurValuesSubtitlePropsType> = ({ subtitle }) => (
  <Typography
    className="our-values-subtitle"
    size={{ base: 30, lg: 82 }}
    align="center"
    color="deep-koamaru"
    weight="medium"
    lineHeight="xsmall"
    dangerouslySetInnerHTML={subtitle}
  />
);

export default OurValuesSubtitle;
