import React, { FC } from 'react';

import Image from 'common/Image';
import Typography from 'common/Typography';

import { ValuesVisualPropsType } from './models';

import './ValuesVisual.scss';

const ValuesVisual: FC<ValuesVisualPropsType> = ({ title, valuesImage }) => (
  <div className="values-visual">
    <section className="values-visual__images">
      {valuesImage?.map(({ image }) => (
        <Image
          className="values-visual__image"
          key={image.imageStructure.alt}
          {...image.imageStructure}
        />
      ))}
    </section>
    <Typography
      weight="bold"
      className="values-visual__title"
      size={{ base: 20, md: 35, xl: 55 }}
      align={{ base: 'center', lg: 'left' }}
      lineHeight="small"
      padding={{
        left: { base: 'unset', lg: 'xl' },
        top: { base: 'md', lg: 'unset' },
      }}
      dangerouslySetInnerHTML={title}
    />
  </div>
);

export default ValuesVisual;
