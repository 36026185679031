import React, { FC } from 'react';
import classNames from 'classnames';

import Infographic from 'common/Infographic';

import { InfographicsPropsType } from './models';

import './Infographics.scss';

const Infographics: FC<InfographicsPropsType> = ({
  infographics,
  className,
  variant = 'fluid',
}) => {
  const infographicsClasses = classNames(
    'infographics',
    { [`infographics--${variant}`]: variant },
    className
  );

  return (
    <div data-testid="infographic-wrapper" className={infographicsClasses}>
      {infographics?.map((tile) => (
        <Infographic key={`${tile.title}-${tile.smallText}`} {...{ ...tile }} />
      ))}
    </div>
  );
};

export default Infographics;
