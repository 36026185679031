import React from 'react';
import classNames from 'classnames';

import { SeparatorPropsType } from './model';

import './Separator.scss';

const Separator: React.FC<SeparatorPropsType> = ({ classname }) => (
  <div className={classNames('separator', classname)}>
    {[0, 1, 2].map((num, i) => (
      <span key={num} className={`separator__item separator__item--${i + 1}`} />
    ))}
  </div>
);

export default Separator;
