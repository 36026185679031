import React, { FC } from 'react';
import classNames from 'classnames';
import { useUISettings } from 'uiSettings';

import Button, { ButtonUIType } from 'common/Button';
import Image from 'common/Image';
import Logo from 'common/Logo';
import Separator from 'common/Separator';
import Typography from 'common/Typography';

import { convertTypography } from 'utils/typography';
import { useScreenService } from 'hooks/useScreenService';

import { BannerImageMappedPositionType, BannerProps, DefaultBannerUIType } from './models.d';

import './Banner.scss';

function nonNullValues(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== null));
}

const Banner: FC<BannerProps> = ({
  mainTitle,
  mainTitleOverwrite,
  subTitle,
  description,
  button,
  image,
  imageMobile,
  className,
  verticalPosition,
  horizontalPosition,
  translateX,
  translateY,
  ui,
  logoVariant,
}) => {
  const { isMobile, isSm } = useScreenService();

  const { variant: defaultBanner } = useUISettings<DefaultBannerUIType>(
    ui?.variant,
    ui?.structureAlias
  );

  const bannerClasses = classNames('banner', className, {
    [`banner--${ui?.variant}`]: ui?.variant,
    [`background--${ui?.backgroundColor}`]: ui?.backgroundColor,
  });

  const bannerContentClasses = classNames('banner__content', {
    [`banner__content--h-${horizontalPosition}`]: horizontalPosition,
    [`banner__content--v-${verticalPosition}`]: verticalPosition,
  });

  const isInfoVariant = ui?.variant === 'info';
  const isProductVariant = ui?.variant === 'product';
  const isHeroVariant = ui?.variant === 'hero-high';

  const isWrapperTitles = isProductVariant || isInfoVariant;

  const showDesktopImage = (image && !isSm) || (image && !imageMobile);
  const showMobileImage = imageMobile && isSm;

  const imagePosition: Partial<BannerImageMappedPositionType> = {
    'hero-high': 'center top',
    info: 'center top',
    product: 'center top',
    promo: 'center top',
  };

  const productTitleStyles = {
    transform: `${translateX ? `translateX(${translateX})` : ''} ${
      translateY ? `translateY(${translateY})` : ''
    }`,
  };

  const titles = isHeroVariant ? (
    <Typography
      {...(convertTypography(ui?.mainTitleTypography, defaultBanner?.mainTitleTypography) || null)}
      className="banner__title banner__title--first"
      as="div"
      dangerouslySetInnerHTML={mainTitleOverwrite || mainTitle}
    />
  ) : (
    <>
      <Typography
        {...(convertTypography(ui?.mainTitleTypography, defaultBanner?.mainTitleTypography) ||
          null)}
        className="banner__title banner__title--first"
        dangerouslySetInnerHTML={mainTitleOverwrite || mainTitle.replace('/n', '<br />')}
      />
      {subTitle ? (
        <Typography
          {...(convertTypography(ui?.subtitleTypography, defaultBanner?.subtitleTypography) ||
            null)}
          className="banner__title banner__title--second"
        >
          {subTitle}
        </Typography>
      ) : null}
    </>
  );

  return (
    <div className={bannerClasses} data-testid="banner-item">
      {showDesktopImage ? (
        <Image
          {...image.imageStructure}
          objectFit="cover"
          objectPosition={imagePosition[ui?.variant]}
          className="banner__image"
        />
      ) : null}
      {showMobileImage ? (
        <Image
          {...imageMobile.imageStructure}
          objectPosition={imagePosition[ui?.variant]}
          className="banner__image"
        />
      ) : null}
      <article className={bannerContentClasses}>
        {!isWrapperTitles ? titles : null}
        {isWrapperTitles ? (
          <div
            className="banner__titles-wrapper"
            style={!isMobile ? productTitleStyles : undefined}
          >
            {titles}
            {isProductVariant && description ? (
              <Typography
                {...(convertTypography(
                  ui?.descriptionTypography,
                  defaultBanner?.descriptionTypography
                ) || null)}
                dangerouslySetInnerHTML={description}
                className="banner__description"
              />
            ) : null}
          </div>
        ) : null}
        {isInfoVariant ? <Separator /> : null}
        {description && !isProductVariant ? (
          <Typography
            {...(convertTypography(
              ui?.descriptionTypography,
              defaultBanner?.descriptionTypography
            ) || null)}
            dangerouslySetInnerHTML={description}
            className="banner__description"
          />
        ) : null}
        {button ? (
          <Button
            {...{
              ...button,
              ui: Object.assign(
                defaultBanner?.buttonUI || ({} as ButtonUIType),
                nonNullValues(button.ui)
              ),
            }}
            className="banner__button"
          >
            {button?.label}
          </Button>
        ) : null}
        {logoVariant ? (
          <Logo variant={logoVariant} className="banner__logo" isLink={false} objectFit="contain" />
        ) : null}
      </article>
    </div>
  );
};

export default Banner;
