import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, Layout } from 'layout';
import Image from 'common/Image';
import Typography from 'common/Typography';
import Banner from 'components/Banner';
import Infographics from 'components/Infographics';
import ValuesVisual from 'components/ValuesVisual';
import OurValuesSubtitle from 'containers/OurValuesSubtitle';

import './OurValues.scss';

const OurValues: FC<OurValuesPageTypes.OurValuesPage> = ({
  data: {
    ourValues: { urls, seo, banner, subtitle, infographic, valuesContent, backgroundImage },
  },
}) => (
  <Layout {...{ urls, seo }} background="bizarre">
    <Banner {...banner} className="our-values__banner" />
    {valuesContent?.valuesIntroduction ? (
      <Container fluid className="our-values__introduction">
        <Container>
          <Container className="our-values__introduction-container">
            <Typography
              size={{ base: 20, md: 35 }}
              weight="bold"
              lineHeight="small"
              padding={{ top: { base: 'md', md: 'xxxl' }, bottom: { base: 'md' } }}
              dangerouslySetInnerHTML={valuesContent.valuesIntroduction.description}
              className="our-values__introduction-description"
            />
            <Typography
              as="span"
              size={{ base: 8, md: 12 }}
              padding={{ top: { base: 'md', md: 'xxxl' }, bottom: { base: 'md' } }}
              dangerouslySetInnerHTML={valuesContent.valuesIntroduction.smallText}
            />
          </Container>
        </Container>
        <Image
          className="our-values__bg-image"
          {...backgroundImage.imageStructure}
          objectFit="contain"
        />
      </Container>
    ) : null}
    {valuesContent?.valuesImageSection ? (
      <ValuesVisual {...valuesContent.valuesImageSection} />
    ) : null}
    {infographic?.length ? <Infographics variant="separated" infographics={infographic} /> : null}
    <OurValuesSubtitle {...{ subtitle }} />
  </Layout>
);

export const query = graphql`
  query OurValuesQuery($lang: String) {
    ourValues(lang: { eq: $lang }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      banner {
        ...BannerFragment
      }
      subtitle
      valuesContent {
        valuesIntroduction {
          ...ContentElementFragment
        }
        valuesImageSection {
          title
          valuesImage {
            image {
              ...ImageStructureFragment
            }
          }
        }
      }
      infographic {
        ...InfographicFragment
      }
      backgroundImage {
        ...TransparentImageStructureFragment
      }
    }
  }
`;

export default OurValues;
